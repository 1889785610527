import React from "react";
import { Avatar, Box, Card, Typography, IconButton } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { fetch_add_to_user_wishlist } from "../../Utils/Services/UserService";
import { useNavigate } from "react-router-dom";

export default function MovieCard({ i, getLikedMovies, showHeart }) {
  const userId = localStorage.getItem("BazaarUserId");
  const Auth = localStorage.getItem("BazaarUserAuth");
  const userType = localStorage.getItem("BazaarUserType");
  const navigate = useNavigate();

  const addToWishlist = async (movId, rigId, wish) => {
    document.getElementById(`wish${movId}`).style.color =
      wish === 1 ? "red" : "#616e80";

    if (Auth && userType !== "Seller") {
      try {
        const responseData = await fetch_add_to_user_wishlist(
          movId,
          userId,
          rigId,
          wish
        );
        if (!responseData.error) {
          getLikedMovies();
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  return (
    <Card
      className="cardBoxshadow"
      sx={{
        borderRadius: "15px",
        maxWidth: 385,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardMedia
        onClick={() =>
          navigate(
            `/livedetail/buyer/${i.activeMovieId}/${i.movieTitle
              .replace(/\s+/g, "")
              .toLowerCase()}`
          )
        }
        component="img"
        alt="green iguana"
        image={i.moviePoster}
        loading="lazy"
        sx={{
          width: "100%",
          height: "350px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <CardContent>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Box
            onClick={() =>
              navigate(
                `/livedetail/buyer/${i.activeMovieId}/${i.movieTitle
                  .replace(/\s+/g, "")
                  .toLowerCase()}`
              )
            }
            textAlign="left"
            display="flex"
            flexDirection="row"
            gap={1}
            alignContent="start"
            alignItems="start"
            justifyContent="start"
          >
            <Avatar
              src={i.userProfile}
              sx={{ width: 24, height: 24, verticalAlign: "middle" }}
            />
            <Typography variant="subtitle2">
              {i.userName ? i.userName : "Movie Metaverse"}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              justifyContent="start"
              textAlign="left"
              flexDirection="column"
              gap={1}
            >
              <Typography variant="body1" color="#101010" fontWeight={600}>
                {i.movieTitle.charAt(0).toUpperCase() + i.movieTitle.slice(1)}
              </Typography>
              <Typography variant="body2" color="#808080">
                {i.sellingRights}
              </Typography>
              <Typography variant="caption" fontWeight={600} color="#5B5561">
                {i.productionName ? i.productionName : "Movie Metaverse"}
              </Typography>
            </Box>
            {userType !== "Seller" && showHeart && (
              <Box
                display="flex"
                justifyContent="end"
                alignContent="end"
                alignItems="end"
              >
                <IconButton
                  onClick={() => addToWishlist(i.movieId, i.rightsId, 0)}
                >
                  <FavoriteIcon
                    id={`wish${i.movieId}`}
                    sx={{
                      verticalAlign: "middle",
                      fontSize: 35,
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
