import React, { useEffect, useState } from "react";
import "./SignUp.css";
import {
  Autocomplete,
  Box,
  Card,
  Link,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTextField from "../Seller/CustomTextField";
import SnackBar from "../../Components/SnackBar/Snackbar";
import RazorUI from "../../RazorPay/RazorUI";
import { LoadingButton } from "@mui/lab";
import PaidIcon from "@mui/icons-material/Paid";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TermsPDF from "../../Assets/Document/Terms and Conditions_New.pdf";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  fetch_get_countries,
  fetch_get_states,
  fetch_create_order,
} from "../../Utils/Services/CommonService.js";
import {
  fetch_delete_user,
  fetch_user_sign_up,
} from "../../Utils/Services/UserService";
import { apibaseUrl } from "../../Utils/BaseAPI/API.jsx";
import { BaseUrl } from "../../Utils/BaseAPI/BaseUrl.js";

export default function SocialSign() {
  const location = useLocation();

  const { response } = location.state || {};
  const [passwordError, setPasswordError] = useState(false);
  const [productionHouse, setProductionHouse] = useState("");
  const [usertype, setUserType] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(response.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [movieTitle, setMovieTitle] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [gstr, setGstr] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [number, setNumber] = useState("");
  const [amount, setAmount] = useState(5900);
  const [Name, setName] = useState(response.name);
  const [productionLogo, setproductionLogo] = useState(null);
  const [acknowledgementChecked, setacknowledgementChecked] = useState(null);
  const [checked, setChecked] = useState(false);
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
    sellerId: null,
  });
  const [loading, setLoading] = useState(false);

  const {
    REACT_APP_RAZORPAY_LIVE_KEY_ID,
    REACT_APP_RAZORPAY_LIVE_KEY_SECRET,
    REACT_APP_RAZORPAY_TEST_KEY_ID,
    REACT_APP_RAZORPAY_TEST_KEY_SECRET,
  } = process.env;

  const isLive = apibaseUrl === BaseUrl.Live;

  const KEYID = isLive
    ? REACT_APP_RAZORPAY_LIVE_KEY_ID
    : REACT_APP_RAZORPAY_TEST_KEY_ID;
  const KEYSECRET = isLive
    ? REACT_APP_RAZORPAY_LIVE_KEY_SECRET
    : REACT_APP_RAZORPAY_TEST_KEY_SECRET;

  if (!KEYID || !KEYSECRET) {
    console.error(
      "Razorpay Key ID or Secret is not defined. Please check your environment variables."
    );
  }

  console.log(response);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setChecked(event.target.checked);
      setacknowledgementChecked(1);
    } else {
      setChecked(false);
      setacknowledgementChecked(0);
    }
  };

  const getCountry = async () => {
    try {
      const responseData = await fetch_get_countries();
      if (!responseData.error) {
        setCountryList(responseData.data);
      }
    } catch (error) {
      setOpen(true);
      setStatus(false);
      setColor(false);
      setMessage("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  const getState = async () => {
    if (country !== "" && country !== null && country !== undefined) {
      try {
        const responseData = await fetch_get_states(country?.countryId);
        if (!responseData.error) {
          setStateList(responseData.data);
        }
      } catch (error) {
        setOpen(true);
        setStatus(false);
        setColor(false);
        setMessage("Oops something went wrong " + error.message);
      }
    }
  };

  useEffect(() => {
    getState();
  }, [country]);

  const handleSubmit = async () => {
    if (!navigator.onLine) {
      setMessage("Your internet is in Offline");
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      try {
        const responseData = await fetch_user_sign_up(
          Name,
          email,
          password,
          productionHouse,
          number,
          usertype,
          streetAddress1,
          streetAddress2,
          city,
          state?.stateName,
          postalCode,
          country?.countryName,
          movieTitle,
          gstr,
          amount,
          productionLogo,
          1,
          1
        );
        if (!responseData.error) {
          setMessage(responseData.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          navigate("/signupconfirmation");
          setDisplayRazorpay(false);
          setLoading(false);
        } else {
          setMessage(responseData.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          setDisplayRazorpay(false);
          setLoading(false);
        }
      } catch (error) {
        setOpen(true);
        setStatus(false);
        setColor(false);
        setMessage("Oops something went wrong " + error.message);
      }
    }
  };

  const paymentFailed = async () => {
    try {
      const responseData = await fetch_delete_user(orderDetails?.sellerId);
      if (!responseData.error) {
        setMessage("Payment unsuccessful. Please Try Again!");
        setOpen(true);
        setStatus(false);
        setColor(false);
        setDisplayRazorpay(false);
      } else {
        setMessage("Something Went Wrong!");
        setOpen(true);
        setStatus(false);
        setColor(false);
        setDisplayRazorpay(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const selectMethod = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      if (acknowledgementChecked !== null) {
        setErrMsg("");
        setLoading(true);
        if (usertype === "Seller") {
          handleCreateOrder();
        } else {
          handleSubmit();
        }
      } else {
        setMessage("Kindly acknowledge and Accept Terms and Conditions");
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } else {
      setErrMsg("Passwords Do Not Match");
    }
  };

  const handleCreateOrder = async () => {
    try {
      const responseData = await fetch_create_order(
        Name,
        email,
        password,
        productionHouse,
        number,
        usertype,
        streetAddress1,
        streetAddress2,
        city,
        state?.stateName,
        postalCode,
        country?.countryName,
        movieTitle,
        gstr,
        amount,
        productionLogo,
        1,
        1
      );
      if (!responseData.error) {
        setMessage(responseData.message);
        setOpen(true);
        setStatus(true);
        setColor(true);
        setDisplayRazorpay(true);
        setOrderDetails({
          orderId: responseData.data.id,
          currency: responseData.data.currency,
          amount: responseData.data.amount,
          sellerId: responseData.data.sellerId,
        });
        setLoading(false);
      } else {
        setMessage(responseData.message);
        setOpen(true);
        setStatus(false);
        setDisplayRazorpay(false);
        setColor(false);
        setLoading(false);
      }
    } catch (error) {
      setOpen(true);
      setStatus(false);
      setColor(false);
      setMessage("Oops something went wrong " + error.message);
    }
  };

  return (
    <Box>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Box position="relative">
        <Box width="100%" p={4} mb={2}>
          <form onSubmit={selectMethod}>
            <Box component={Card} textAlign="left">
              <Box p={4}>
                <Box textAlign="center">
                  <Typography
                    variant="h4"
                    color="#735DA5"
                    fontWeight={600}
                    mb={4}
                  >
                    Let Us Know More About You ...
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Your Name *</Typography>
                      <CustomTextField
                        onChange={setName}
                        value={Name}
                        validationRegex={/^[A-Za-z0-9\s]+$/}
                        errorMessage={"Forbidden character: %<>$'\""}
                        required={true}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Buyer / Seller *</Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["Buyer", "Seller"]}
                        value={usertype}
                        sx={{
                          mt: 1,
                        }}
                        onChange={(event, value) => setUserType(value)}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "20px",
                              },
                            }}
                            required
                            size="small"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  {usertype === "Seller" && (
                    <Grid item xs={12} sm={4} md={4}>
                      <Box>
                        <Typography>Production House *</Typography>
                        <CustomTextField
                          onChange={setProductionHouse}
                          value={productionHouse}
                          validationRegex={/^[A-Za-z0-9\s]+$/}
                          errorMessage={"Forbidden character: %<>$'\""}
                          required={true}
                        />
                      </Box>
                    </Grid>
                  )}

                  {usertype === "Seller" && (
                    <Grid item xs={12} sm={4} md={4}>
                      <Box>
                        <Typography>Movie Title to be Listed *</Typography>
                        <CustomTextField
                          onChange={setMovieTitle}
                          value={movieTitle}
                          validationRegex={/^[A-Za-z0-9\s]+$/}
                          errorMessage={"Forbidden character: %<>$'\""}
                          required={true}
                        />
                      </Box>
                    </Grid>
                  )}

                  {usertype === "Seller" && (
                    <Grid item xs={12} sm={4} md={4}>
                      <Box>
                        <Typography>Production House Logo</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          InputProps={{
                            sx: {
                              borderRadius: "20px",
                              borderColor: "#735DA5",
                              mt: 1,
                            },
                          }}
                          type="file"
                          accept="image/*"
                          autoComplete="off"
                          onChange={(e) => setproductionLogo(e.target.files[0])}
                        />
                      </Box>
                    </Grid>
                  )}

                  {usertype === "Seller" && (
                    <Grid item xs={12} sm={4} md={4}>
                      <Box>
                        <Typography>GSTR No. (optional)</Typography>
                        <TextField
                          name="gstr"
                          fullWidth
                          size="small"
                          InputProps={{
                            sx: {
                              borderRadius: "20px",
                              borderColor: "#735DA5",
                              mt: 1,
                            },
                          }}
                          value={gstr}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setGstr(newValue);
                          }}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Contact Number *</Typography>
                      <Box mt={1}>
                        <PhoneInput
                          country={"in"}
                          onChange={(phone) => {
                            const phoneNumber = phone.slice(2);
                            setNumber(phoneNumber);
                          }}
                          autoComplete="off"
                        />
                      </Box>
                    </Box>
                  </Grid>

                  {/*  <Grid item xs={6} sm={4} md={4}>
          <Box>
            <Typography>Mobile OTP *</Typography>
            <CustomTextField
            value={mobileOTP}
            onChange={setMobileOTP}
            validationRegex={/^[0-9]+$/}
            errorMessage={"Enter a Numeric Value"}
            />
            </Box>
          </Grid> */}

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Billing Address </Typography>
                      <CustomTextField
                        onChange={setStreetAddress1}
                        value={streetAddress1}
                        validationRegex={/^[\w\d\s#.,\/\\\-_@*]+$/i}
                        errorMessage={"Forbidden character: %<>$'\""}
                        required={false}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>City *</Typography>
                      <CustomTextField
                        onChange={setCity}
                        value={city}
                        validationRegex={/^[A-Za-z]+$/}
                        errorMessage={"Forbidden character: %<>$'\""}
                        required={true}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Country *</Typography>
                      <Autocomplete
                        id="combo-box-demo"
                        size="small"
                        onChange={(event, value) => setCountry(value)}
                        options={countryList}
                        required
                        sx={{
                          mt: 1,
                        }}
                        getOptionLabel={(option) => option.countryName}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "20px",
                              },
                            }}
                            size="small"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>State *</Typography>
                      <Autocomplete
                        id="combo-box-demo"
                        size="small"
                        onChange={(event, value) => setState(value)}
                        options={stateList}
                        required
                        sx={{
                          mt: 1,
                        }}
                        getOptionLabel={(option) => option.stateName}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "20px",
                              },
                            }}
                            size="small"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Postal Code *</Typography>
                      <CustomTextField
                        onChange={setPostalCode}
                        value={postalCode}
                        validationRegex={/^[0-9]+$/}
                        errorMessage={"Enter a Numeric Value"}
                        required={true}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Set New Password *</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "20px",
                            borderColor: "#735DA5",
                            mt: 1,
                          },
                        }}
                        variant="outlined"
                        type="password"
                        id="password"
                        required
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Box>
                      <Typography>Confirm Password *</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "20px",
                            borderColor: "#735DA5",
                            mt: 1,
                          },
                        }}
                        variant="outlined"
                        type="password"
                        id="password"
                        required
                        autoComplete="current-password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        helperText={errMsg}
                        error={passwordError}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box py={1}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Onboarding Fee For a Movie ₹10000 + 18% GST.
                      </Typography>
                      <Box display="flex" alignItems="center">
                        {/* <Typography variant='subtitle2' sx={{ textDecoration: 'line-through', mr: 1 }} fontWeight={600}>₹{originalFee}</Typography> */}
                        <Typography
                          variant="subtitle2"
                          color="secondary"
                          fontWeight={600}
                        >
                          50% Launch Discount
                        </Typography>
                      </Box>
                      {/* <Typography variant='subtitle2' fontWeight={600}>₹{discountedFee}</Typography> */}
                    </Box>
                    <FormGroup required>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="* I acknowledge and agree to the terms and conditions."
                      />
                      {checked && (
                        <Box mt={1}>
                          <Typography variant="body2" color="textSecondary">
                            By checking this box, you confirm that you have read
                            and agree to our
                            <Link
                              href={TermsPDF}
                              target="_blank"
                              rel="noopener noreferrer"
                              underline="none"
                              color="#707070"
                            >
                              <Typography variant="body2" color="primary">
                                Terms and Conditions
                              </Typography>
                            </Link>
                          </Typography>
                        </Box>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <LoadingButton
                        sx={{
                          width: {
                            lg: "50ch",
                            md: 500,
                            sm: "100%",
                            xs: "100%",
                          },
                          bgcolor: "#735DA5",
                          fontWeight: 600,
                          boxShadow: 1,
                          color: "#fff",
                          borderRadius: "3px",
                          ":hover": { bgcolor: "#735DA5", color: "#fff" },
                        }}
                        type="submit"
                        endIcon={
                          usertype === "Seller" ? (
                            <PaidIcon />
                          ) : (
                            <HowToRegIcon />
                          )
                        }
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                      >
                        <span>
                          {usertype === "Seller"
                            ? "Proceed To Pay"
                            : "Complete Registration"}
                        </span>
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>

      {displayRazorpay ? (
        <RazorUI
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          sellerId={orderDetails.sellerId}
          keyId={KEYID}
          keySecret={KEYSECRET}
          setDisplayRazorpay={setDisplayRazorpay}
          handleSubmit={handleSubmit}
          paymentFailed={paymentFailed}
          email={email}
          productionHouse={productionHouse}
          name={Name}
          password={password}
          usertype={usertype}
          number={number}
          setLoading={setLoading}
          setShowConfirmation={false}
          savedType={""}
        />
      ) : null}

      <Footer />
    </Box>
  );
}
