import "./App.css";
import { HashRouter as Router } from "react-router-dom";
import Routes from "./Routes/Routes";
import { createTheme, ThemeProvider } from "@mui/material";
import { clusterApiUrl, Keypair } from "@solana/web3.js";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { useMemo } from "react";
import { UserProvider } from "./Context/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Gilroy-Medium"].join(","),
      h6: {
        color: "#735DA5",
      },
      h4: {
        color: "#1E1E1E",
      },
      h3: {
        color: "#735DA5",
      },

      subtitle1: {
        color: "#735DA5",
      },
      subtitle2: {
        color: "#735DA5",
      },
    },
    Link: {
      fontFamily: ["Gilroy-Medium"].join(","),
    },
    button: {
      backgroundColor: "#735DA5",
      fontFamily: "Gilroy-Medium",
    },
  });

  const network = process.env.REACT_APP_SOLANA_NETWORK || "devnet";

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  // const wallets = useMemo(
  //   () => [
  //     new PhantomWalletAdapter(),
  //     new SolflareWalletAdapter({ network }),
  //   ],
  //   []
  // );

  const inAppWalletAdapter = {
    name: "InAppWallet",
    signTransaction: async (transaction) => {
      const keypair = Keypair.generate();
      transaction.partialSign(keypair);
      return [keypair];
    },
    connect: async () => {
      // Implement connect logic if needed
    },
    disconnect: async () => {
      // Implement disconnect logic if needed
    },
    on: (event, callback) => {
      // Implement event listener logic if needed
    },
    off: (event, callback) => {
      // Implement event removal logic if needed
    },
  };

  const wallets = useMemo(() => [inAppWalletAdapter], []);

  // const confirmAndSignTransaction = async (transaction) => {
  //   try {
  //     const feePayer = Keypair.fromSecretKey(decode(privateKey));
  //     const recoveredTransaction = Transaction.from(
  //         Buffer.from(encodedTransaction, 'base64')
  //     );
  //     recoveredTransaction.partialSign(feePayer);
  //     const signedTx = await wallet.signTransaction(recoveredTransaction);
  //     const confirmTransaction = await connection.sendRawTransaction(
  //         signedTx.serialize()
  //     );
  //     return confirmTransaction;

  //   } catch (error) {
  //       console.error("Error signing transaction:", error);
  //       throw error;
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets}>
          <WalletDialogProvider>
            <UserProvider>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GG_CLIENT_ID}
              >
                <Router>
                  <Routes />
                </Router>
              </GoogleOAuthProvider>
            </UserProvider>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
}

export default App;
