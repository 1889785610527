import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetch_get_stack_cards } from "../../Utils/Services/HomePageServices";
import { StackCarosuel } from "../../Variables/Variables";

export default function StackCards() {
  const cardsBoxRef = useRef(null);
  let autoChangeInterval;
  const navigate = useNavigate();
  const [cardstack, setCardstack] = useState([]);

  useEffect(() => {
    const getstackcards = async () => {
      try {
        const response = await fetch_get_stack_cards("");
        if (!response.error) {
          setCardstack(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getstackcards();
  }, []);

  useEffect(() => {
    const sliderImagesBox = document.querySelectorAll(".cards-box");

    const autoChange = () => {
      sliderImagesBox.forEach((el) => {
        const clickEvent = new Event("click", { bubbles: true });
        el.dispatchEvent(clickEvent);
      });
    };

    autoChangeInterval = setInterval(autoChange, 3000); // Change every 3 seconds, adjust as needed

    // Clear interval on component unmount
    return () => {
      clearInterval(autoChangeInterval);
    };
  }, []);

  useEffect(() => {
    let sliderImagesBox = document.querySelectorAll(".cards-box");
    sliderImagesBox.forEach((el) => {
      let imageNodes = el.querySelectorAll(".card:not(.hide)");
      let arrIndexes = Array.from({ length: imageNodes.length }, (_, i) => i);

      let setIndex = (arr) => {
        for (let i = 0; i < imageNodes.length; i++) {
          imageNodes[i].dataset.slide = arr[i]; // Set indexes
        }
      };

      el.addEventListener("click", () => {
        arrIndexes.unshift(arrIndexes.pop());
        setIndex(arrIndexes);
      });
      setIndex(arrIndexes); // The first indexes addition
    });
  }, []);

  return (
    <div className="cards-box" ref={cardsBoxRef}>
      {StackCarosuel.map((imageUrl, index) => (
        <div
          className="card"
          key={index}
          onClick={() =>
            navigate(
              `/dealdonedetail/${imageUrl.imageId}/${imageUrl.TITLE.replace(
                /\s+/g,
                ""
              ).toLowerCase()}`
            )
          }
        >
          <div className="content-placeholder">
            <div className="row">
              <img
                src={imageUrl.images}
                alt="stacks"
                style={{
                  width: "100%",
                  height: "430px",
                  backgroundSize: "cover",
                  borderRadius: "14px",
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
